import { Configuration } from '../../../type/configuration';

export default {
	CKEditorHandler: {
		config: {
			stylesCombo_stylesSet: 'lbbwStyles',
		},
		stylesets: {
			name: 'lbbwStyles',
			styles: [
				{
					name: 'Weiß',
					element: 'font',
					attributes: {
						'class': 'ckeditorWhite',
					},
				}, {
					name: 'Grün',
					element: 'font',
					attributes: {
						'class': 'ckeditorGreen',
					},
				}, {
					name: 'Blau',
					element: 'font',
					attributes: {
						'class': 'ckeditorBlue',
					},
				},
			],
		},
	},
} as Configuration;